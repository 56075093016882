import style from './GreenButton.module.css';
import React, { ReactEventHandler, ReactNode } from 'react';
import Link from 'next/link';
import { clsx } from 'clsx';

interface GreenButtonProps {
  url?: string;
  onClick?: ReactEventHandler;
  target?: string;
  className?: string;
  form?: string;
  linkBtnClassName?: string;
  linkBtnColor?: string;
  btnColor?: string;
  padding?: string;
  children: ReactNode;
  disabled?: boolean;
  gradientOff?: boolean;
}

export const GreenButton = ({
  url,
  target,
  onClick = () => {},
  className = '',
  linkBtnClassName = '',
  linkBtnColor = 'bg-green-default',
  btnColor = 'bg-green-default',
  children,
  form,
  disabled = false,
  padding = 'px-[5vw] py-[18px]',
  gradientOff = false,
}: GreenButtonProps) => {
  return url ? (
    target === '_blank' ? (
      <a href={url} target={target} className={clsx(style.link, className)}>
        <button
          disabled={disabled}
          form={form}
          className={clsx(
            'font-jamjuree',
            linkBtnColor,
            style.greenButton,
            linkBtnClassName,
            padding
          )}
          onClick={onClick}
        >
          <div className={style.buttonText}>{children}</div>
          {!gradientOff && <div className={style.gradient}></div>}
        </button>
      </a>
    ) : (
      <Link href={url} className={clsx(style.link, className)} prefetch={false}>
        <button
          disabled={disabled}
          form={form}
          className={clsx(
            'font-jamjuree',
            linkBtnColor,
            style.greenButton,
            linkBtnClassName,
            padding
          )}
          onClick={onClick}
        >
          <div className={style.buttonText}>{children}</div>
          {!gradientOff && <div className={style.gradient}></div>}
        </button>
      </Link>
    )
  ) : (
    <button
      disabled={disabled}
      form={form}
      className={clsx('font-jamjuree', style.greenButton, className, btnColor, padding)}
      onClick={onClick}
    >
      <div className={style.buttonText}>{children}</div>
      {!gradientOff && <div className={style.gradient}></div>}
    </button>
  );
};
